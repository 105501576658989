:root {
    --size-xl: 1440px;
    --size-l: 1024px;
    --size-m: 768px;
    --size-s: 600px;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: url('./images/Background.svg');

    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}

.body-2 {
    /* background-image: url('./images/BgImage.svg');
      background-repeat: no-repeat;
      background-position: 93% 100px; */
}

.body-3 {
    /* Icons background */
    /* background-image: url("./images/TopGroup.svg"); */
    background-repeat: no-repeat;
    background-position: 53% 3%;
}

.body-4 {
    /* Icons background */
    /* background-image: url("./images/BottomGroup.svg"); */
    background-repeat: no-repeat;
    background-position: 50% 91.5%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sticky-header {
    padding-top: 48px;
}

/* p:not(.featureDescriptionContent) {
  margin: 0;
} */

.modal-header .close {
    padding: 0rem 1rem !important;
}

.modal-header .modal-title {
    display: flex;
    justify-content: center;
}

.deleteAccountDialog .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 70%);
    width: 500px !important;
}

.modal-content .modal-header {
    width: 100%;
    border-bottom: none;
    display: flex;
    justify-content: center;
    padding: 0;
}

.modal-body > p {
    margin-bottom: 0;
}

.deleteAccountDialog .modal-footer {
    border-top: none;
    margin-top: 15px;
    padding: 0;
}

.isLoadingModalContent {
    height: 310px;
}

.tooltip-inner {
    max-width: 100% !important;
}

.ms-Dialog-title {
    font-family: 'TTHoves-DemiBold';
    font-weight: 700;
}

.ms-Dialog-main {
    border-radius: 10px;
}

.ms-Dialog-subText {
    font-family: 'segoeuii';
    font-size: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #909096;
}

.ms-Dialog-inner {
    padding: 0 24px 0;
}

/* Font-face */
@font-face {
    font-family: segoeuii;
    font-display: swap;
    src: url('fonts/segoeuii.ttf');
}

@font-face {
    font-family: ntailu;
    font-display: swap;
    src: url('fonts/ntailu.ttf');
}

@font-face {
    font-family: TTHoves-DemiBold;
    font-display: swap;
    src: url('fonts/TTHoves-DemiBold.ttf');
}

.text {
    background-image: url('./images/Rectangle.jpg');
    background-size: 100% 100%;
    border-radius: 20px;
}

@media (max-width: --size-xl) {
    .body-2 {
        background-position: 100% 3%;
    }

    .body-3 {
        background-position: 53% 3.5%;
    }

    .body-4 {
        background-position: 50% 91%;
    }
}

@media (max-width: --size-l) {
    .body-2 {
        background-image: none;
    }

    .body-3 {
        background-position: 53% 3.5%;
    }

    .body-4 {
        background-position: -110% 91%;
    }
}

@media (max-width: --size-m) {
    .text {
        height: auto;
    }

    .body-3 {
        background-position: 53% 3.5%;
    }
}

@media (max-width: --size-s) {
    .text {
        display: flex;
        flex-direction: column-reverse;
        background-image: url('./images/RectangleMobile.svg');
        width: 100%;
        height: auto;
        background-size: cover;
    }

    /* * Doesn't work, maybe remove this */
    input[type='submit'] {
        background-image: url('./images/arrowWhiteRight.svg');
        background-position: center center;
        background-size: 50%;
        background-repeat: no-repeat;
    }
}

@media (min-height: 360px) and (max-width: 640px) {
    .deleteAccountDialog .modal-content {
        top: 50%;
        left: 50%;
        transform: translate(-50%, 25%);
    }
}

@media (min-height: 411px) and (max-width: 731px) {
    .deleteAccountDialog .modal-content {
        top: 50%;
        left: 50%;
        transform: translate(-50%, 25%);
    }
}

@media (min-height: 411px) and (max-width: 823px) {
    .deleteAccountDialog .modal-content {
        top: 50%;
        left: 50%;
        transform: translate(-50%, 20%);
    }
}
