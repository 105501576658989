.providersChoiceGroupContainer :global .ms-ShimmerElementsGroup-root {
    height: 112px;
}

.providersChoiceGroupContainer :global .ms-Modal-scrollableContent {
    overflow: auto;
}

.providersChoiceGroupContainer :global .ms-ChoiceFieldGroup-flexContainer {
    overflow: hidden;
}

.providersChoiceGroupContainer :global .ms-ChoiceFieldGroup-flexContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 300px;
}

.providersChoiceGroupContainer :global .ms-ChoiceField {
    height: 110px;
    margin: 10px;
}

.providersChoiceGroupContainer :global .ms-ChoiceField:last-child,
.providersChoiceGroupContainer :global .ms-ChoiceField :nth-last-child(2) {
    margin-bottom: 2px;
}

.providersChoiceGroupContainer
:global
.ms-ChoiceField
.ms-ChoiceField-wrapper
.ms-ChoiceField-field
.ms-ChoiceField-innerField
.ms-ChoiceField-imageWrapper,
.providersChoiceGroupContainer
:global
.ms-ChoiceField
.ms-ChoiceField-wrapper
.ms-ChoiceField-field
.ms-ChoiceField-innerField
.ms-ChoiceField-imageWrapper
.is-hidden {
    width: 75px;
    height: 75px;
}

.providersChoiceGroupContainer
:global
.ms-ChoiceField
.ms-ChoiceField-wrapper
.ms-ChoiceField-field
.ms-ChoiceField-innerField
.ms-ChoiceField-imageWrapper
.ms-Image,
.providersChoiceGroupContainer
:global
.ms-ChoiceField
.ms-ChoiceField-wrapper
.ms-ChoiceField-field
.ms-ChoiceField-innerField
.ms-ChoiceField-imageWrapper
.is-hidden
.ms-Image {
    width: 75px !important;
    height: 75px !important;
}

@media (min-width: 480px) {
    .providersChoiceGroupContainer :global .ms-ShimmerElementsGroup-root {
        height: 172px;
    }

    .providersChoiceGroupContainer :global .ms-ChoiceField {
        width: 180px;
        height: 180px;
        border: 2px solid #d8e8fc;
        border-radius: 10px;
    }

    .providersChoiceGroupContainer
    :global
    .ms-ChoiceField
    .ms-ChoiceField-wrapper {
        width: 180px;
        height: 180px;
        background: white !important;
    }

    .providersChoiceGroupContainer
    :global
    .ms-ChoiceField
    .ms-ChoiceField-wrapper
    .ms-ChoiceField-field {
        padding: 0;
    }

    .providersChoiceGroupContainer
    :global
    .ms-ChoiceField
    .ms-ChoiceField-wrapper
    .ms-ChoiceField-field
    .ms-ChoiceField-innerField
    .ms-ChoiceField-imageWrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0;
        width: 95px;
        height: 95px;
    }

    .providersChoiceGroupContainer
    :global
    .ms-ChoiceField
    .ms-ChoiceField-wrapper
    .ms-ChoiceField-field
    .ms-ChoiceField-innerField
    .ms-ChoiceField-imageWrapper
    .ms-Image {
        width: 95px !important;
        height: 95px !important;
    }

    .providersChoiceGroupContainer
    :global
    .ms-ChoiceField
    .ms-ChoiceField-wrapper
    .ms-ChoiceField-field
    .ms-ChoiceField-labelWrapper {
        display: none;
    }
}

.accountSecondaryText {
    overflow: hidden;
    text-overflow: ellipsis;
}
